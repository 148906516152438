/* eslint-disable */
import HttpService from '../HttpService';
export default class CarrierInterfaceService {
    constructor() {
      this._httpService = new HttpService('/odoo/carrier-interface');
    }

    async FindAllByCarrierId(carrierId) {
      return await this._httpService.get(`?carrier.id=${carrierId}`);
    }
 };
